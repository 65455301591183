import React, { useRef, useState } from "react";
import IframeResizer from "iframe-resizer-react";
import { Divider, Select } from "antd";
import PropTypes from "prop-types";
import MessageData from "../messageData";

const { Option } = Select;

const EmbedTest = ({ scrolling, style }) => {
  const ref = useRef(null);
  const [messageData, setMessageData] = useState(undefined);
  const [url, setUrl] = useState(
    "https://3ie-iframe-issues--inflowchart.netlify.app/chart/3ie/decision-tree-for-3ies-development-evidence-portal-dep"
  );

  const onResized = (data) => setMessageData(data);

  const onMessage = (data) => {
    setMessageData(data);
    ref.current.sendMessage("Hello back from parent page");
  };

  return (
    <>
      <div id="iframeResizer-top"></div>
      <Select
        defaultValue="https://release-test.inflowchart.com/chart/3ie/decision-tree-for-3ies-development-evidence-portal-dep"
        onChange={setUrl}
      >
        <Option value="https://www.inflowchart.com/chart/niv/acuteboekje/hemodynamische-instabiliteit?embedded=true">
          https://www.inflowchart.com/chart/niv/acuteboekje/hemodynamische-instabiliteit?embedded=true
        </Option>
        <Option value="https://www.inflowchart.com/chart/niv/acuteboekje/breed-qrs-complex-tachycardie/">
          https://www.inflowchart.com/chart/niv/acuteboekje/breed-qrs-complex-tachycardie/
        </Option>
        <Option value="https://www.inflowchart.com/chart/katnh/periprocedureel-beleid-vka">
          https://www.inflowchart.com/chart/katnh/periprocedureel-beleid-vka
        </Option>
        <Option value="https://release-test.inflowchart.com/chart/3ie/decision-tree-for-3ies-development-evidence-portal-dep">
        https://release-test.inflowchart.com/chart/3ie/decision-tree-for-3ies-development-evidence-portal-dep
        </Option>
      </Select>
      <Divider />
      <IframeResizer
        log
        inPageLinks
        forwardRef={ref}
        onMessage={onMessage}
        onResized={onResized}
        src={url}
        width="100%"
        scrolling={scrolling}
        heightCalculationMethod="taggedElement"
        style={{ width: "1px", minWidth: "100%", border: "1px solid grey" }}
      />
      <messageData />
      <div id="iframeResizer-bottom"></div>
    </>
  );
};

MessageData.defaultProps = {
  data: undefined,
};

MessageData.propTypes = {
  data: PropTypes.object,
};

export default EmbedTest;
