import React from "react";
import LayoutMain from "../layouts/LayoutMain";
import Seo from "../components/seo";
import EmbedTest from "../components/ui/EmbedTest";
import { Row, Col, Typography, Divider } from "antd";
const { Title } = Typography;

const EmbedTestPage = () => (
  <LayoutMain>
    <Seo
      title="Embed test"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      className="heading"
      style={{ backgroundColor: "#fff", textAlign: "center" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title>Embed test</Title>
        <Title level={2} style={{ color: "white" }}>
          Test 123
        </Title>
      </Col>
    </Row>
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={20} xl={20} style={{ padding: "50px" }}>
        <EmbedTest />
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
  </LayoutMain>
);

export default EmbedTestPage;
